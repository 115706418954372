<template>
    <div id="tabla" class="row flex-col items-center sm:items-stretch pt-4 pb-4">
        
        <div class="row flex-col sm:flex-row alinear-items">
            <div class="col-sm-3 text-center">
                <img 
                    class="w-32 mr-6" 
                    :src="'https://cotizador.socasesores.com/uploads/'+ item.Logo" 
                    :alt="item.Banco">
            </div>
            <div class="col-sm-3 text-center tabla-dato-prod">{{ item.Producto }}</div>
            <div class="col-sm-3 text-center tabla-dato-prod">$ {{ numberFormat(item.MensualidadInicialPromedio) }}</div>
            <div class="col-sm-3 text-center tabla-dato-prod flex flex-row justify-between"> 
                <div 
                    @click="tablaAmortizacionPrev(item)"
                    class="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" height="40"  width="40" viewBox="0 -960 960 960"  fill="#006D4E"><path d="M300-286q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-164q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm0-164q12 0 21-9t9-21q0-12-9-21t-21-9q-12 0-21 9t-9 21q0 12 9 21t21 9Zm132 328h244v-60H432v60Zm0-164h244v-60H432v60Zm0-164h244v-60H432v60ZM180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600v-600H180v600Zm0-600v600-600Z"/></svg>
                </div>
                <div>
                    <slot name="boton-accion-visualizar" />
                </div>
            </div>
      	</div>

        <vs-popup 
            fullscreen title="Tabla de amortización" 
            :active.sync="popupActive">
            
            <vs-prompt 
                title="Exportar a Excel" 
                class="export-options" 
                cancel-text="Cancelar" 
                @cancel="clearFields" 
                @accept="exportToExcel" 
                accept-text="Exportar" 
                @close="clearFields" 
                :active.sync="activePrompt">
                
                <input v-model="fileName" placeholder="Nombre del archivo" class="w-full" />
                <v-select v-model="selectedFormat" :options="formats" class="my-4" />
                <div class="flex">
                    <span class="mr-4">Tamaño de la celda automático:</span>
                    <vs-switch v-model="cellAutoWidth">Tamaño automático</vs-switch>
                </div>
            </vs-prompt>
            
            <vs-table 
                :data="itemsTable" 
                max-items="12"
                pagination
                noDataText="No hay datos para mostrar">
                
                <template slot="header">
                    <vs-button 
                        @click="activePrompt=true;popupActive=false;" 
                        class="export">Exportar a tabla de Excel</vs-button>
                    <vs-button 
                        @click="sendEmail(item)" 
                        class="export">Enviar por correo</vs-button>
                </template>
                
                <template slot="thead">
                    <vs-th>Mes</vs-th>
                    <vs-th>Capital</vs-th>
                    <vs-th>Tasa</vs-th>
                    <vs-th>Intereses</vs-th>
                    <vs-th>Amortización</vs-th>
                    <vs-th>Mensualidad</vs-th>
                    <vs-th>Gastos</vs-th>
                    <vs-th>Pago Mensual</vs-th>
                </template>
                
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].Mes" >{{data[indextr].Mes}}</vs-td>
                        <vs-td :data="data[indextr].Capital">$ {{numberFormat(data[indextr].Capital)}}</vs-td>
                        <vs-td :data="data[indextr].Tasa">{{numberFormat(data[indextr].Tasa)}}%</vs-td>
                        <vs-td :data="data[indextr].Interes">$ {{numberFormat(data[indextr].Interes)}}</vs-td>
                        <vs-td :data="data[indextr].Amortizacion">$ {{numberFormat(data[indextr].Amortizacion)}}</vs-td>
                        <vs-td :data="data[indextr].Mensualidad">$ {{numberFormat(data[indextr].Mensualidad)}}</vs-td>
                        <vs-td :data="data[indextr].GastosDiferidos">$ {{numberFormat(data[indextr].GastosDiferidos)}}</vs-td>
                        <vs-td :data="data[indextr].PagoMensual">$ {{numberFormat(data[indextr].PagoMensual)}}</vs-td>
                    </vs-tr>
                </template>
            
            </vs-table>

            <div class="">
                <h5>Totales</h5>
                <vs-table :data="itemsTable">
                    <template slot="thead">
                        <vs-th>Meses Totales</vs-th>
                        <vs-th>Monto de crédito</vs-th>
                        <vs-th>Tasa</vs-th>
                        <vs-th>Suma intereses</vs-th>
                        <vs-th>Suma amortización</vs-th>
                        <vs-th>Suma mensualidad</vs-th>
                        <vs-th>Gastos totales</vs-th>
                        <vs-th v-if="item.BancoId != 21">Pago Mensual total</vs-th>
                        <vs-th v-if="item.BancoId == 21">Pago total</vs-th>
                    </template>
                    
                    <template>
                        <vs-tr>
                            <vs-td>{{mesesTotales}}</vs-td>
                            <vs-td>$ {{numberFormat(amortizacionTotal)}}</vs-td>
                            <vs-td>{{numberFormat(tasa)}}%</vs-td>
                            <vs-td>$ {{numberFormat(interesesTotales)}}</vs-td>
                            <vs-td>$ {{numberFormat(amortizacionTotal)}}</vs-td>
                            <vs-td>$ {{numberFormat(mensualidadTotal)}}</vs-td>
                            <vs-td>$ {{numberFormat(gastosTotales)}}</vs-td>
                            <vs-td>$ {{numberFormat(pagoMensualTotal)}}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>

        </vs-popup>

    </div>
</template>

<script>
import vSelect from 'vue-select';
import axios from 'axios';

export default {
    name: "CalculadoraProductoItem",
    components: {
        vSelect
    },
    props: {
        idCaso: {
            type: Number,
            required: false
        },
        item: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            // itemId: '',
            solicitante: '',
            fileName: '',
            formats: ['xlsx', 'csv', 'txt'],
            cellAutoWidth: true,
            selectedFormat: 'xlsx',
            headerTitle: ['Mes', 'Capital', 'Tasa', 'Interes', 'Amortizacion','Mensualidad','Gastos','Pago'],
            headerVal: ['Mes', 'Capital', 'Tasa', 'Interes', 'Amortizacion','Mensualidad','GastosDiferidos','PagoMensual'],
            activePrompt: false,
            popupActive: false,
            interesesTotales: 0,
            mensualidadTotal: 0,
            amortizacionTotal: 0,
            gastosTotales: 0,
            pagoMensualTotal: 0,
            mesesTotales: 0,
            montoSolicitado: 0,
            tasa: 0,
            itemsTable: [],
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getApikey();
        console.log("CreandoCalculadoraProductoItem");
        if(this.id){
            this.dameCaso();
        }
    },
    watch: {},
    computed: {},
	methods: {
        getApikey() {
            if (!JSON.parse(localStorage.getItem("userInfo")).ApiKey) {
                this.$router.push("/login");
            } else {
                this.key = JSON.parse(localStorage.getItem("userInfo")).ApiKey;
            }
        },
        numberFormat(num){
            return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        tablaAmortizacionPrev(item) {
            if(item.BancoId == 33) {
                this.tablaAmorKredi(item); 
            } else {
                this.tablaAmortizacion(item); 
            }
        },
        tablaAmorKredi(item) {
            let self = this;
            // if( this.id > 0 ) {
            //     this.idCaso = this.id // TODO: Revisar
            // }
            
            this.$vs.loading({
                container: '#tabla',
                scale: 0.6
            });
            
            var product_application = {
                idTablaAmortizacion: item.JsonAmortizacion,
                plazoKredi: item.Plazo
            }
            this.$axios
            .post('https://cotizador.socasesores.com/ApiION/MethodoKredAmortizacion.php', product_application, { headers: { 'Content-Type': 'application/json; charset=UTF-8' } })
            .then((response) => {
                
                this.interesesTotales = 0;
                this.mensualidadTotal = 0;
                this.amortizacionTotal = 0;
                this.gastosTotales = 0;
                this.pagoMensualTotal = 0;
                this.mesesTotales = 0;
                this.montoSolicitado = 0;
                this.tasa = 0;
                this.itemsTable = response.data.objContenido;
                this.popupActive = true;
                
                for (var i = 0; i < this.itemsTable.length; i++) {
                    this.interesesTotales = this.interesesTotales + this.itemsTable[i].Interes;
                    this.mensualidadTotal = this.mensualidadTotal + this.itemsTable[i].Mensualidad;
                    this.amortizacionTotal = this.amortizacionTotal + this.itemsTable[i].Amortizacion;
                    this.gastosTotales = this.gastosTotales + this.itemsTable[i].GastosDiferidos;
                    this.pagoMensualTotal = this.pagoMensualTotal + this.itemsTable[i].PagoMensual;
                    this.tasa = this.itemsTable[i].Tasa;
                    this.mesesTotales = this.itemsTable[i].Mes;
                    this.montoSolicitado = this.itemsTable[i].Capital;
                }
                
                this.$vs.loading.close('#tabla > .con-vs-loading');
            })
            .catch(function (error) {
                self.$vs.notify({
                    title: 'Ocurrio un errros en api KREDI',
                    text: error,
                    color: 'danger',
                    position: 'top-right',
                })
            });
        },
        tablaAmortizacion(item) {
            let self = this;
            // if(this.id > 0) {
            //     this.idCaso = this.id; // TODO: Revisar
            // }
            
            let TablaPagosIon = '';
            let AmortizacionPdf = '';
            let AmortizacionJson = '';
            
            if (item.BancoId == 21) {
                TablaPagosIon = true;
                AmortizacionPdf = item.PdfAmortizacion;
                AmortizacionJson = item.JsonAmortizacion;
            }
            
            this.$vs.loading({
                container: '#tabla',
                scale: 0.6
            });
            
            let objRequestDameTablaPagos = {
                strApiKey: this.key,
                strMetodo: 'DameTablaPagos',
                objResultado: {
                    Id: item.Id,
                    caso: this.id,
                    // caso: this.idCaso, // TODO: ?
                    IonAmortizacion: TablaPagosIon,
                    pdfIon: AmortizacionPdf,
                    jsonIon: AmortizacionJson,
                    plazo: item.Plazo,
                    tasa: item.Tasa
                }
            }
            
            this.$axios.post('/',objRequestDameTablaPagos,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then(response => {
                if(response.data.intEstatus != 0) {
                    this.$vs.notify({
                        title: 'Ocurrio un error en DameTablaPagos',
                        text: response.data.strError,
                        color: 'danger',
                        position: 'top-right'
                    })
                } else {
                    if(item.BancoId == 21) {
                        // this.DescargaPdfIon(response.data.objContenido)
                        // this.$vs.loading.close('#tabla > .con-vs-loading')
                        this.interesesTotales = 0;
                        this.mensualidadTotal = 0;
                        this.amortizacionTotal = 0;
                        this.gastosTotales = 0;
                        this.pagoMensualTotal = 0;
                        this.mesesTotales = 0;
                        this.montoSolicitado = 0;
                        this.tasa = 0;
                        this.itemsTable = response.data.objContenido;
                        this.popupActive = true;

                        for (var i = 0; i < this.itemsTable.length; i++) {
                            this.interesesTotales = this.interesesTotales + this.itemsTable[i].Interes;
                            this.mensualidadTotal = this.mensualidadTotal + this.itemsTable[i].Mensualidad;
                            this.amortizacionTotal = this.amortizacionTotal + this.itemsTable[i].Amortizacion;
                            this.gastosTotales = this.gastosTotales + this.itemsTable[i].GastosDiferidos;
                            this.pagoMensualTotal = this.pagoMensualTotal + this.itemsTable[i].PagoMensual;
                            this.tasa = this.itemsTable[i].Tasa;
                            this.mesesTotales = this.itemsTable[i].Mes;
                            this.montoSolicitado = this.itemsTable[i].Capital;
                        }
                        this.$vs.loading.close('#tabla > .con-vs-loading')
                    } else {
                        this.interesesTotales = 0;
                        this.mensualidadTotal = 0;
                        this.amortizacionTotal = 0;
                        this.gastosTotales = 0;
                        this.pagoMensualTotal = 0;
                        this.mesesTotales = 0;
                        this.montoSolicitado = 0;
                        this.tasa = 0;
                        this.itemsTable = response.data.objContenido;
                        this.popupActive = true;
                        
                        for (var i = 0; i < this.itemsTable.length; i++) {
                            this.interesesTotales = this.interesesTotales + this.itemsTable[i].Interes;
                            this.mensualidadTotal = this.mensualidadTotal + this.itemsTable[i].Mensualidad;
                            this.amortizacionTotal = this.amortizacionTotal + this.itemsTable[i].Amortizacion;
                            this.gastosTotales = this.gastosTotales + this.itemsTable[i].GastosDiferidos;
                            this.pagoMensualTotal = this.pagoMensualTotal + this.itemsTable[i].PagoMensual;
                            this.tasa = this.itemsTable[i].Tasa;
                            this.mesesTotales = this.itemsTable[i].Mes;
                            this.montoSolicitado = this.itemsTable[i].Capital;
                        }

                        this.$vs.loading.close('#tabla > .con-vs-loading');
                    }
                }
            })
            .catch(function (error) {
                self.$vs.notify({
                    title:'Ocurrio un error de sistema',
                    text:error,
                    color:'danger',
                    position:'top-right'
                })
            })
        },
        clearFields () {
            this.filename = '';
            this.cellAutoWidth = true;
            this.selectedFormat = 'xlsx';

            console.log( 'limpiando campos' );
        },
        exportToExcel () {
            import('@/vendor/Export2Excel').then(excel => {
                const list = this.itemsTable;
                const data = this.formatJson(this.headerVal, list);
                excel.export_json_to_excel({
                    header: this.headerTitle,
                    data,
                    filename: this.fileName,
                    autoWidth: this.cellAutoWidth,
                    bookType: this.selectedFormat
                });
                this.clearFields();
            })
        },
        formatJson (filterVal, jsonData) {
            return jsonData.map(v => filterVal.map(j => {
                return v[j];
            }));
        },
        dameCaso() {
            let self = this;
            let objRequestDameCaso = {
                strApiKey: this.key,
                strMetodo: 'DameCaso',
                objCaso: {
                    Id: this.id
                }
            }
            this.$axios.post( '/', objRequestDameCaso,{
                headers: {
                    'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'
                }
            })
            .then(response => {
                if(response.data.intEstatus == 0) {
                    let cotizacion = response.data.objContenido;
                    this.solicitante = cotizacion.Solicitante;
                } else {
                    this.$vs.notify({
                        title: 'Ocurrio un error en DameCaso',
                        text: response.data.strError,
                        color: 'danger',
                        position: 'top-right'
                    })
                }
            })
            .catch(function (error) {
                self.$vs.notify({
                    title: 'Ocurrio un error de sistema',
                    text:error,
                    color:'danger',
                    position:'top-right'
                })
            })
        },
        sendEmail(variant) {
            let self=this
            let objEmail = {
                to: this.solicitante,
                case: this.id,
                token: this.key,
                variant: variant.Id,
                variant_name: variant.Producto,
                bank: variant.Banco,
                amortizacionIon: variant.JsonAmortizacion
            }
            // se cambio el php
            axios.post('https://cotizador.socasesores.com/admin/formatos/emailComparaleProd/EmailAmortizacionPDF.php',objEmail,{headers : {'Content-Type':'application/json; charset=UTF-8'}})
            .then(//response => {
                this.$vs.notify({
                    title: 'Se envió correctamente el correo electrónico a',
                    text: this.solicitante,
                    position:'top-right'
                })
            // }
            )
            .catch(function (error) {
                self.$vs.notify({
                    title: 'Ocurrio un error de sistema',
                    text: error,
                    color:'danger',
                    position:'top-right'
                })
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.alinear-items{
	align-items: center;
}
.tabla-dato-prod{
	color:#006D4E;
	text-align: center;
}
// .btn-visualizar {
// 	color: #006D4E;
// 	background-color: #F5F5F5;
// 	border-color: #F5F5F5;
// }

.export {
    margin-bottom: 3%;
    float: right;
    margin-left: 1%;
}
</style>